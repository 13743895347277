import React from 'react';

const IntroMessage = () => (
    <div className="intro">
        <div className='name_wrap'><h1 className="name animate-charcter">Country Quiz!</h1></div>
        
        <p className="intro-text">
        How extensive is your world geography knowledge? Can you identify the countries in the Balkans? How quickly can you recognize African nations? Choose a quantity and a continent to put your skills to the test!        </p>
    </div>
)

export default IntroMessage;