/* - Check for Synonyms */
import React, { Component } from 'react';
import { ratio } from '../../node_modules/fuzzball/fuzzball.js';
import { Spring } from 'react-spring';

// Make a general button element?

class EnterForm extends Component {
	constructor(props) {
		super(props);
		this.handleSubmitCountry = this.handleSubmitCountry.bind(this);
		this.handleSkip = this.handleSkip.bind(this);
		this.handleHint = this.handleHint.bind(this);
		this.enteredCountry = React.createRef();
	}

	state = {
		clickedHint: false,
	};

	handleSkip(event) {
		this.props.changeMessage('skipped');
		this.props.nextCountry('skipped');
		// Flip clickedHint back to false because we're going to the next country
		this.setState({ clickedHint: false });
	}

	handleHint = (event) => {
		event.preventDefault();
		// Update message in parent state
		this.props.changeMessage('hint');
		this.setState({ clickedHint: true }, function () {
			console.log(this.state.clickedHint);
		});
	};

	handleSubmitCountry(event) {
		event.preventDefault();

		const submittedCountry = this.enteredCountry.current.value.toLowerCase();
		const correctCountry = this.props.country;
		const fuzzRatio = ratio(correctCountry.name, submittedCountry);

		if (fuzzRatio > 82 || correctCountry.synonyms.includes(submittedCountry)) {
			this.props.nextCountry('correct');
			this.props.changeMessage('correct');
			this.setState({ clickedHint: false });
		} else {
			this.props.changeMessage('incorrect');
		}

		event.currentTarget.reset();
	}

	render() {
		return (
			<Spring force from={{ opacity: 0 }} to={{ opacity: 1 }}>
				{(styles) => (
					<div style={styles}>
						<form autoComplete="off" onSubmit={this.handleSubmitCountry}>
							<input
								id="country-answer"
								name="country-answer"
								list="country-list"
								type="text"
								ref={this.enteredCountry}
								required
								placeholder="country"
								className="text-input"
							/>
							<button className="button button-left" type="submit" disabled={!this.props.started}>
								Enter
							</button>
							<button
								className="button button-middle"
								type="submit"
								onClick={this.handleHint}
								disabled={!this.props.started || this.state.clickedHint}
							>
								Hint
							</button>
							{/* <button
          className="button"
          disabled={!this.props.started}>
            Neighbors
        </button>*/}
							<button
								className="button button-right"
								type="button"
								onClick={this.handleSkip}
								disabled={!this.props.started}
							>
								Skip
							</button>
							<datalist id="country-list">
								<option value="italy"></option>
								<option value="albania"></option>
								<option value="afghanistan"></option>
								<option value="morocco"></option>
								<option value="usa"></option>
								<option value="australia"></option>
								<option value="germany"></option>
								<option value="russia"></option>
								<option value="niger"></option>
								<option value="netherlands"></option>
								<option value="china"></option>
								<option value="algeria"></option>
								<option value="angola"></option>
								<option value="antarctica"></option>
								<option value="argentina"></option>
								<option value="armenia"></option>
								<option value="austria"></option>
								<option value="azerbaijan"></option>
								<option value="bangladesh"></option>
								<option value="belarus"></option>
								<option value="belgium"></option>
								<option value="belize"></option>
								<option value="benin"></option>
								<option value="bhutan"></option>
								<option value="bolivia"></option>
								<option value="bosnia and herzegovina"></option>
								<option value="botswana"></option>
								<option value="brazil"></option>
								<option value="bulgaria"></option>
								<option value="burkina faso"></option>
								<option value="burundi"></option>
								<option value="cambodia"></option>
								<option value="cameroon"></option>
								<option value="canada"></option>
								<option value="central african republic"></option>
								<option value="chad"></option>
								<option value="chile"></option>
								<option value="colombia"></option>
								<option value="costa rica"></option>
								<option value="croatia"></option>
								<option value="cuba"></option>
								<option value="cyprus"></option>
								<option value="czech republic"></option>
								<option value="democratic republic of the congo"></option>
								<option value="denmark"></option>
								<option value="dominican republic"></option>
								<option value="east timor"></option>
								<option value="ecuador"></option>
								<option value="egypt"></option>
								<option value="el salvador"></option>
								<option value="equatorial guinea"></option>
								<option value="eritrea"></option>
								<option value="estonia"></option>
								<option value="ethiopia"></option>
								<option value="falkland islands"></option>
								<option value="finland"></option>
								<option value="france"></option>
								<option value="french polynesia"></option>
								<option value="gabon"></option>
								<option value="georgia"></option>
								<option value="ghana"></option>
								<option value="greece"></option>
								<option value="greenland"></option>
								<option value="guatemala"></option>
								<option value="guinea-bissau"></option>
								<option value="guinea"></option>
								<option value="guyana"></option>
								<option value="haiti"></option>
								<option value="honduras"></option>
								<option value="hungary"></option>
								<option value="iceland"></option>
								<option value="india"></option>
								<option value="indonesia"></option>
								<option value="iran"></option>
								<option value="iraq"></option>
								<option value="ireland"></option>
								<option value="israel"></option>
								<option value="ivory coast"></option>
								<option value="japan"></option>
								<option value="jordan"></option>
								<option value="kazakhstan"></option>
								<option value="kenya"></option>
								<option value="kosovo"></option>
								<option value="kuwait"></option>
								<option value="kyrgyzstan"></option>
								<option value="laos"></option>
								<option value="latvia"></option>
								<option value="lebanon"></option>
								<option value="liberia"></option>
								<option value="libya"></option>
								<option value="lithuania"></option>
								<option value="luxembourg"></option>
								<option value="madagascar"></option>
								<option value="malawi"></option>
								<option value="malaysia"></option>
								<option value="mali"></option>
								<option value="mauritania"></option>
								<option value="mexico"></option>
								<option value="moldova"></option>
								<option value="mongolia"></option>
								<option value="mozambique"></option>
								<option value="myanmar"></option>
								<option value="namibia"></option>
								<option value="nepal"></option>
								<option value="new caledonia"></option>
								<option value="new zealand"></option>
								<option value="nicaragua"></option>
								<option value="nigeria"></option>
								<option value="north korea"></option>
								<option value="norway"></option>
								<option value="oman"></option>
								<option value="pakistan"></option>
								<option value="palestine"></option>
								<option value="panama"></option>
								<option value="papua new guinea"></option>
								<option value="paraguay"></option>
								<option value="peru"></option>
								<option value="philippines"></option>
								<option value="poland"></option>
								<option value="portugal"></option>
								<option value="qatar"></option>
								<option value="republic of macedonia"></option>
								<option value="republic of the congo"></option>
								<option value="romania"></option>
								<option value="rwanda"></option>
								<option value="saudi arabia"></option>
								<option value="senegal"></option>
								<option value="serbia"></option>
								<option value="sierra leone"></option>
								<option value="slovakia"></option>
								<option value="slovenia"></option>
								<option value="solomon islands"></option>
								<option value="south africa"></option>
								<option value="south korea"></option>
								<option value="south sudan"></option>
								<option value="spain"></option>
								<option value="sri lanka"></option>
								<option value="sudan"></option>
								<option value="suriname"></option>
								<option value="sweden"></option>
								<option value="switzerland"></option>
								<option value="syria"></option>
								<option value="taiwan"></option>
								<option value="tajikistan"></option>
								<option value="tanzania"></option>
								<option value="thailand"></option>
								<option value="the bahamas"></option>
								<option value="the gambia"></option>
								<option value="trinidad and tobago"></option>
								<option value="tunisia"></option>
								<option value="turkey"></option>
								<option value="turkish republic of northern cyprus"></option>
								<option value="turkmenistan"></option>
								<option value="uganda"></option>
								<option value="ukraine"></option>
								<option value="united arab emirates"></option>
								<option value="united kingdom"></option>
								<option value="uruguay"></option>
								<option value="uzbekistan"></option>
								<option value="vanuatu"></option>
								<option value="venezuela"></option>
								<option value="vietnam"></option>
								<option value="yemen"></option>
								<option value="zambia"></option>
								<option value="zimbabwe"></option>
							</datalist>
						</form>
					</div>
				)}
			</Spring>
		);
	}
}

export default EnterForm;
